.leftnavstyle {
  background-color: #0d2129 !important;

  font-size: 16px;

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.adminlogoContainer {
  margin-top: 25px;
  margin-bottom: 30px;

  width: 15rem;
}

.adminlogo {
  color: #48bf84;
  font-family: "Noto Serif", serif !important;
  font-weight: 700 !important;

  font-size: 26px;
}

.adminlogo2 {
  color: #48bf84;
  font-weight: 700 !important;

  font-size: 18px;
}

.navLinkStyle {
  color: gainsboro !important;

  &:hover {
    background-color: #13313d;
  }
}

.navLinkStyleActive {
  color: #48bf84 !important;
  background-color: #13313d;
  font-weight: 700 !important;
}

.teacherEmail {
  color: gainsboro !important;
}
