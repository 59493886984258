.scroll {
  width: 2.5rem;
  height: 2.5rem;

  position: fixed;
  right: 0.9rem;

  bottom: 1em;

  border-radius: 50%;
  border: none;
  outline: none !important;

  cursor: pointer;

  background-color: #48bf84;
  color: white;
  padding: 8px;
  font-size: 1.4rem;

  &:hover {
    background-color: #36a16c;
    transition: 0.3s;
  }
}
