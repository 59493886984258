%baseButton {
  background-color: transparent !important;
  border-color: transparent !important;

  font-size: 16px !important;
  padding: 1px 5px !important;

  outline: none;
  box-shadow: none !important;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }

  &:disabled {
    color: gray !important;
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: gray !important;
    }
  }
}

.button {
  @extend %baseButton;
  color: #48bf84 !important;
}

.deleteButton {
  @extend %baseButton;
  color: #bf4848 !important;
}
