.customTabs > a {
  background-color: transparent;
  border-bottom-color: transparent;
  color: #4d535a;
  &:hover {
    color: #48bf84;
  }
}

.customTabs > a.active {
  &:hover {
    border: 0;
    color: #4d535a;
  }
}

.customTabs > a.disabled {
  color: gray;
}
