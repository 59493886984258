@import "../../styles/colors";

$jumbotronBg: "/images/landing/jumbo.jpg";
$jumbotronBgSm: "/images/landing/jumboSm.jpg";

.jumbotron {
  background-blend-mode: multiply;
  background-color: rgb(103, 103, 103) !important;
  background-image: url($jumbotronBg);
  background-size: cover;
  margin-bottom: 0px;
  padding: 90px 0px;
  h2 {
    color: $mainGreen;
    font-size: 57px;
    font-weight: 800 !important;
    text-align: left;
  }
  div {
    p {
      color: white;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  a {
    background-color: $mainGreen;
    border-color: $mainGreen !important;
    color: white;
    font-size: 20px;
    padding: 7px 25px;
    &:hover {
      background-color: $mainGreenDark;
      color: white;
    }
  }
}

.wrapper {
  section:first-child {
    padding: 5rem 0rem 0rem 0rem;
  }
  section:last-child {
    margin-bottom: 5rem;
  }

  .whoAreWe {
    h2 {
      padding: 30px 0 8px;
      position: relative;
    }
    h2::before {
      background: $lightGreen;
      bottom: 16px;
      content: "";
      height: 14px;
      left: 7%;
      position: absolute;
      transform: skew(-12deg) translateX(-50%);
      width: 150px;
      z-index: -1;
    }
    .row {
      margin-bottom: 3rem;
    }
    img {
      margin-top: 3.5rem;
    }
  }
  .ourCourses {
    h1 {
      margin-bottom: 3rem;
      position: relative;
      text-align: center;
      text-decoration: none;
    }
    h1::after {
      background: $lightGreen;
      content: "";
      height: 20px;
      left: 50%;
      position: absolute;
      top: 60%;
      transform: skew(-12deg) translateX(-50%);
      width: 15%;
      z-index: -1;
    }
    .courseMenuItem {
      background-color: transparent;
      border: 1px solid gainsboro;
      margin-bottom: 10px;
      padding: 25px !important;
    }
    .activeCourseMenuItem {
      @extend .courseMenuItem;
      border-left: 0.4rem solid $mainGreen;
    }
  }
  .misionAndVision {
    h2 {
      margin-bottom: 1rem;
      position: relative;
    }
    h2::after {
      background: $lightGreen;
      content: "";
      height: 14px;
      position: absolute;
      right: 60%;
      top: 50%;
      transform: skew(-12deg) translateX(-50%);
      width: 150px;
      z-index: -1;
    }
    img {
      margin-bottom: 3.5rem;
    }
  }
}

@media only screen and (max-width: 991px) {
  .jumbotron {
    background-image: url($jumbotronBgSm);
    padding: 120px 0px !important;
    h2 {
      font-size: 42px;
    }
    div {
      p {
        font-size: 16px;
      }
    }
  }
}
