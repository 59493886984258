@import "colors";

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400 !important;
  color: $mainGray !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Lato", sans-serif !important;
  font-weight: 700 !important;
  color: $secondaryGreen;
}

.dropdown-toggle {
  font-family: "Noto Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding-left: 0px;
  padding-right: 0px;
}

.dropdown-toggle::after {
  display: none !important;
}

// generic button
.genericButton {
  background-color: $mainGreen !important;
  border-color: $mainGreen !important;
  color: $white !important;

  &:hover {
    background-color: $mainGreenDark !important;
    border-color: $mainGreenDark !important;
  }
}

// hr
.myDivider {
  margin-top: 0;
  border: none;
  height: 0.4rem !important;
  width: 2.5rem;
  background-color: $mainGreen;
  margin-left: 0;
}

@media only screen and (max-width: 991px) {
  hr.dashedDivider {
    margin: 45px 0px !important;
  }
}
