.card {
  background-color: #f4fbf8 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #50575e !important;
  width: 24rem;
}

.title {
  font-weight: 900 !important;
}

.clickableCard {
  @extend .card;
  cursor: pointer;
  transition: all ease 0.2s;
}

.clickableCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

@media only screen and (max-width: 991px) {
  .card {
    width: 100%;
  }
}
