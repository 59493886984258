.listGroupItem {
  &:hover {
    background-color: #dbffed !important;
    transition: 0.3;
  }
}

.link {
  color: #308b5e !important;
}
