.wrapper {
  margin: 100px 0px;
  padding: 0px 380px;

  p, li {
    margin: 1rem 0rem;
    overflow-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
  }
}

.title {
  border-left: solid 1px #0f5257;
  border-right: solid 1px #0f5257;
  color: #0f5257;
  display: inline-block;
  font-size: 45px;
  font-weight: 900 !important;
  padding: 0 10px;
  position: relative;
}

.title:before,
.title:after {
  border-bottom: 1px solid #0f5257;
  bottom: 23px;
  content: "";
  position: absolute;
  width: 30px;
}

.title:before {
  left: -30px;
}

.title:after {
  right: -30px;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    margin: 100px 0px;
    padding: 0px 30px;
  }

  .title {
    font-size: 30px;
  }
  .title:before,
  .title:after {
    bottom: 13px;
    width: 20px;
  }
  .title:before {
    left: -20px;
  }

  .title:after {
    right: -20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1390px) {
  .wrapper {
    padding: 0px 200px;
  }
}
