.itemstyle {
  color: #495057 !important;
  background-color: ghostwhite !important;

  &:hover {
    background-color: rgb(239, 239, 245) !important;
  }

  &.active {
    background-color: #48bf84 !important;
    border-color: #37ac71 !important;
    color: white !important;
  }

  &:active {
    background-color: #48bf84 !important;
    border-color: #37ac71 !important;
    color: white !important;
  }
}

.itemtext {
  font-size: 28px;
  font-family: "Lato", sans-serif !important;
  font-weight: 900;
}
