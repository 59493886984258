.container {
  background-color: #0d2129;
  color: #7f898e;
  margin-top: auto;
  padding: 60px;
  padding-bottom: 40px;
}

.logo {
  color: gainsboro;
  font-family: "Noto Serif", serif !important;
}

.title {
  color: gainsboro;
}

.socialsIcon {
  color: gainsboro;
  cursor: pointer;
  font-size: 20px;
}

.linksWrapper {
  margin-top: 15px;
  text-align: center;
  a {
    color: #48bf84;
    font-size: small;
    text-decoration: none;
    &:hover {
      color: #48bf84;
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 40px 3px;
  }
  .linksWrapper {
    text-align: left;
  }
}
