.topicName {
  font-size: 56px !important;
  color: #0f5257 !important;
  font-weight: bolder;
  margin-bottom: 0px;
}

.topicSubject {
  color: #48bf84 !important;
  font-size: 24px !important;
  font-weight: 600;
  margin-bottom: 5px;
}

.subtitle {
  margin-bottom: 0px;
  font-weight: 900 !important;
}

@media only screen and (max-width: 991px) {
  .topicName {
    font-size: 35px !important;
  }
}
