.su_container {
  margin-top: 48px;
  margin-bottom: 48px;
}

.su_leftCol {
  background-color: #0f5257;
  padding: 45px;
}

.su_title {
  font-weight: 700;
}

.su_rightCol {
  padding: 45px;
}

@media only screen and (max-width: 991px) {
  .su_container {
    margin-top: 0px;
  }
  .su_leftCol {
    padding: 35px 15px !important;
  }
  .su_rightCol {
    padding: 15px;
    padding-top: 30px;
  }
}
