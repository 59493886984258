.log_container {
  margin-top: 48px;
  margin-bottom: 48px;
}

.log_leftCol {
  background-color: #0f5257;
  padding: 45px;
}

.log_rightCol {
  padding: 45px;
}

@media only screen and (max-width: 991px) {
  .log_container {
    margin-top: 0px;
  }
  .log_leftCol {
    padding: 35px 15px !important;
  }
  .log_rightCol {
    padding: 15px;
    padding-top: 30px;
  }
}
