.grayTooltip {
  color: gray;
  cursor: help;

  &:hover {
    color: rgb(182, 179, 179) !important;
    transition: 0.3s;
  }
}

.mainTooltip {
  color: #48bf84 !important;
  cursor: help;

  &:hover {
    color: #89fcc2 !important;
    transition: 0.3s;
  }
}
