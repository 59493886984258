.toggleButton {
  border: 1px solid #ced4da !important;
  cursor: default !important;
  width: 100%;
}

.anchorToggle {
  cursor: default !important;
  text-decoration: none !important;
  color: #495057 !important;
}
