.cardHeader {
  background-color: #e7edee !important;
}

.arrowIcon {
  color: #48bf84;
  margin-right: 0.4rem;
}

.accordionToggle {
  box-shadow: none;
  text-decoration: none;
}

.underConstructionDiv {
  background-color: white;
  background-blend-mode: multiply;

  padding: 55px 0px !important;
}

.underConstruction {
  font-size: 80px !important;
}

.examName {
  font-weight: 900 !important;
  font-size: 26px;
}
