@import "../../../styles/colors";

.leftCol {
  background-color: #f2f2f2;
}

.topicTitle {
  font-weight: 900 !important;
}

.chart {
  margin: 35px 0px !important;
  max-height: 500px !important;
  max-width: 500px !important;
}

.topic {
  color: $mainGray !important;
  &:hover {
    background-color: $lightGreen;
  }
}

.active {
  background-color: $secondaryGreen !important;
  color: white !important;
  font-weight: bolder;
}
