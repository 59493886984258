.backButton {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: #48bf84 !important;
  font-size: 17px !important;
  font-weight: bolder !important;
  outline: none;
  padding: 0px !important;

  &:hover {
    color: #75ca9f !important;
    transition: 0.2s;
  }
}

.container {
  background-color: #0d2129;
  margin-bottom: 10px;
  padding-bottom: 68px;
  padding-top: 45px;
}

@media only screen and (max-width: 991px) {
  .topicName {
    font-size: 55px !important;
  }
}
